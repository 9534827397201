/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    ol: "ol",
    li: "li",
    div: "div",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Esto es un blog de GNU/Linux pero tambien es de electrónica, pero a veces surgen incompatibilidades, por ejemplo ahora que estoy usando el Isis en la universidad y no se encuentra para Linux pero aun así me parece un programa tan bueno que veo necesario poner este manual."), "\n", React.createElement(_components.p, null, "Tengo que decir que el manual no es mio se lo debo a la UPV, ademas solo es una iniciación, explica lo mas básico para que tu te sepas manejar a partir de ahi."), "\n", React.createElement(_components.p, null, "Podeis bajaros la versión Lite de este programa ", React.createElement(_components.a, {
    href: "http://www.ieeproteus.com/descarga.html"
  }, "aquí"), "."), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Seleccionar los componentes del circuito:"), "\n"), "\n", React.createElement(_components.p, null, "a. Activar el icono de componentes ", React.createElement(_components.a, {
    href: "http://litox9.files.wordpress.com/2008/09/image001.jpg"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 31px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 106.4516129032258%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAVABQDASIAAhEBAxEB/8QAGQABAAIDAAAAAAAAAAAAAAAAAAECAwQF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAHrW175ZUqiSAr/xAAcEAACAgIDAAAAAAAAAAAAAAAAAQMRAiETMTL/2gAIAQEAAQUCXWxEl8ETyoXlPR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAWEQADAAAAAAAAAAAAAAAAAAARICH/2gAIAQIBAT8BNT//xAAaEAACAgMAAAAAAAAAAAAAAAAAEAFREUFi/9oACAEBAAY/AjanFHNqH//EABwQAAICAgMAAAAAAAAAAAAAAAABETEhQVFh8P/aAAgBAQABPyF3GJpaPEI2GXLtMLoc5aNYrYuCLRIgrZ//2gAMAwEAAgADAAAAENcfwf/EABYRAQEBAAAAAAAAAAAAAAAAAAEQMf/aAAgBAwEBPxAyE//EABgRAQADAQAAAAAAAAAAAAAAAAEAEBEx/9oACAECAQE/EHGKesJ//8QAHxABAAICAQUBAAAAAAAAAAAAAQARITFRQWFxocGB/9oACAEBAAE/EG5UAwODmF00gKRa07fBEDBkC0V62V5LhYQHc07cndr9ns/CFcCDYl3giIABMHE9n4T/2Q=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"image001\"\n        title=\"image001\"\n        src=\"/static/7e7a6183ddf42cbe652c0afb51534fe0/67336/image001.jpg\"\n        srcset=\"/static/7e7a6183ddf42cbe652c0afb51534fe0/67336/image001.jpg 31w\"\n        sizes=\"(max-width: 31px) 100vw, 31px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }))), "\n", React.createElement(_components.p, null, "b. Pulsar icono P (DEVICES) y escribir nombre de componente."), "\n", React.createElement(_components.p, null, "Ejemplos: resistencia (RES), diodos LED (LED-RED), circuitos digitales con su nombre, (74HC194), entrada lógica (LOGICSTATE), etc"), "\n", React.createElement(_components.p, null, "Tambien de puede buscar los componentes por categorías y subcategorias"), "\n", React.createElement(_components.p, null, "c. Una vez selecciondo el componente aparecerá su nombre en la lista DEVICES, que actuará como una bandeja de componentes"), "\n", React.createElement(_components.p, null, "d. Repetir la búsqueda para todos los componentes del circuito"), "\n", React.createElement(_components.ol, {
    start: "2"
  }, "\n", React.createElement(_components.li, null, "Colocar los componentes en la hoja de trabajo"), "\n"), "\n", React.createElement(_components.p, null, "a. Seleccionar un componente de la bandeja, llevar el ratón sobre el lugar deseado de la hoja y pulsar boton ", React.createElement(_components.strong, null, "izquierdo")), "\n", React.createElement(_components.p, null, "b. Mover componente: boton ", React.createElement(_components.strong, null, "derecho"), " sobre el componente, se selecciona (rojo), arrastrarlo pulsando el boton ", React.createElement(_components.strong, null, "izquierdo")), "\n", React.createElement(_components.p, null, "c. Editar componente (ej. Cambiar valor resistencia): boton ", React.createElement(_components.strong, null, "derecho"), " sobre componente, se selecciona (rojo), click en botón ", React.createElement(_components.strong, null, "izquierdo,"), " aparece pantalla de edición del compenente"), "\n", React.createElement(_components.p, null, "d. Borrar componente: boton ", React.createElement(_components.strong, null, "derecho"), " sobre componente, se selecciona (rojo), boton ", React.createElement(_components.strong, null, "derecho"), " se borra"), "\n", React.createElement(_components.p, null, "e. Girar, reflejar componente: boton ", React.createElement(_components.strong, null, "derecho"), " sobre componente, se selecciona (rojo), pulsar iconos de giro ", React.createElement(_components.a, {
    href: "http://litox9.files.wordpress.com/2008/09/image002.jpg"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 21px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 85.71428571428571%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAARABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAECBAX/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQP/2gAMAwEAAhADEAAAAehk001m8YVIQD//xAAaEAEAAwADAAAAAAAAAAAAAAADAAECERMg/9oACAEBAAEFAmS8zsUkj5vmlVNeP//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABYRAAMAAAAAAAAAAAAAAAAAAAIgMv/aAAgBAgEBPwEZT//EACAQAAIABQUBAAAAAAAAAAAAAAECABEhMUEDEBIgMlH/2gAIAQEABj8CVV9NaAhPOcqytsuotSmPsAIhUZLdf//EABsQAQABBQEAAAAAAAAAAAAAAAERABAhQWGh/9oACAEBAAE/IcS54lrtJp+4WWKqnhsohDcTy7b/2gAMAwEAAgADAAAAECgYQf/EABYRAAMAAAAAAAAAAAAAAAAAAAEgMf/aAAgBAwEBPxA1P//EABgRAQEAAwAAAAAAAAAAAAAAAAERIEFR/9oACAECAQE/ECQgJu9w/8QAHhABAAEEAwEBAAAAAAAAAAAAAQARITFREEGxYaH/2gAIAQEAAT8QKyXsd18hVnQXga0NVjljSwINFf0tBJ8aJbsD75wZnXj/2Q=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"image002\"\n        title=\"image002\"\n        src=\"/static/863f0a297f140608852a8a7c5e7ed0e0/3aed4/image002.jpg\"\n        srcset=\"/static/863f0a297f140608852a8a7c5e7ed0e0/3aed4/image002.jpg 21w\"\n        sizes=\"(max-width: 21px) 100vw, 21px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), " o reflexión ", React.createElement(_components.a, {
    href: "http://litox9.files.wordpress.com/2008/09/image003.jpg"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 21px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 66.66666666666666%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAUBAgT/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAgP/2gAMAwEAAhADEAAAAWWFpFLgZv/EABoQAAIDAQEAAAAAAAAAAAAAAAACARESExT/2gAIAQEAAQUCd6b1T2KszGz/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAXEQEBAQEAAAAAAAAAAAAAAAACARAx/9oACAECAQE/ASjOzP/EABsQAAICAwEAAAAAAAAAAAAAAAEREBIAAjFB/9oACAEBAAY/AgFgFNlxTb1R/8QAHBABAAICAwEAAAAAAAAAAAAAAQARIVEQQWGR/9oACAEBAAE/IXIXvsa1ZyRldk+xFL6cRUMs0HXH/9oADAMBAAIAAwAAABAoH//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAEDAQE/EEZ//8QAFxEAAwEAAAAAAAAAAAAAAAAAARAhMf/aAAgBAgEBPxANrF//xAAdEAEBAAEEAwAAAAAAAAAAAAABEQAQITFRQZHw/9oACAEBAAE/EI9FFBw6MiyPyQfBmzyemPMVsunKiQZvFrNP/9k='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"image003\"\n        title=\"image003\"\n        src=\"/static/0f360e987da657c18ea45c79a170e797/3aed4/image003.jpg\"\n        srcset=\"/static/0f360e987da657c18ea45c79a170e797/3aed4/image003.jpg 21w\"\n        sizes=\"(max-width: 21px) 100vw, 21px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }))), "\n", React.createElement(_components.ol, {
    start: "3"
  }, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Alimentación y Masa. Activar el icono ", React.createElement(_components.a, {
    href: "http://litox9.files.wordpress.com/2008/09/image004.jpg"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 28px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 78.57142857142857%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAQABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIEBf/EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAAB6k1GGgV//8QAGBABAQEBAQAAAAAAAAAAAAAAAgEAAxL/2gAIAQEAAQUCd8kMu5xIcQjd/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFhEAAwAAAAAAAAAAAAAAAAAAEBEh/9oACAECAQE/Aax//8QAGhAAAQUBAAAAAAAAAAAAAAAAAAECEBESUf/aAAgBAQAGPwLSlRTei6j/xAAbEAEBAQACAwAAAAAAAAAAAAABIQARwTFBUf/aAAgBAQABPyEVreIGkSPmh1nTJ9D70NAhxTozv//aAAwDAQACAAMAAAAQFB//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAWEQADAAAAAAAAAAAAAAAAAAABEDH/2gAIAQIBAT8QMF//xAAcEAEBAQEAAgMAAAAAAAAAAAABESEAMXFBYZH/2gAIAQEAAT8QpkCZRrDXoGUYSJ7TOxJmdQNKwCK+FEPzmEQwI0tcPrvh67//2Q=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"image004\"\n        title=\"image004\"\n        src=\"/static/b03ffbc71fb1f7d88084eabbcc7a9796/dcb9a/image004.jpg\"\n        srcset=\"/static/b03ffbc71fb1f7d88084eabbcc7a9796/dcb9a/image004.jpg 28w\"\n        sizes=\"(max-width: 28px) 100vw, 28px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), " Seleccionar POWER, GROUND, etc. Actuar como un componente"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Señales de entradas, señal de reloj. Activar el icono ", React.createElement(_components.a, {
    href: "http://litox9.files.wordpress.com/2008/09/image005.jpg"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 27px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 100%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAECBAX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB68ZrRrEAAf/EABsQAAIBBQAAAAAAAAAAAAAAAAECAwAQERIg/9oACAEBAAEFAicUrBrTKSY1G3H/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAcEAABAwUAAAAAAAAAAAAAAAABETJRAAIQEiD/2gAIAQEABj8Ckxl+oIRaWxvP/8QAHBAAAgICAwAAAAAAAAAAAAAAAREhMQBRECBh/9oACAEBAAE/IYwRJQXlbB0eHhmjTB5tjSEqB5HX/9oADAMBAAIAAwAAABDkEEH/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/EB//xAAWEQEBAQAAAAAAAAAAAAAAAAARASD/2gAIAQIBAT8QIOP/xAAdEAEBAAIDAQEBAAAAAAAAAAABESExABBBUWGB/9oACAEBAAE/EDYeeNHrlCf3gmCBWGvoiifop0gkoxQZEfS2jqheYcCk7AEYfSmL1Zrm29f/2Q=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"image005\"\n        title=\"image005\"\n        src=\"/static/158e1e29141b58faa19245d0fe7e5d50/ee7d7/image005.jpg\"\n        srcset=\"/static/158e1e29141b58faa19245d0fe7e5d50/ee7d7/image005.jpg 27w\"\n        sizes=\"(max-width: 27px) 100vw, 27px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), " Seleccionar DCLOCK, DPULSE, etc. Actuar como un componente"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Lineas de conexión. Ratón sobre extremo de componente, cuando aparezca X arrastrar hasta otro extemo de la linea, botón izquierdo."), "\n"), "\n"), "\n", React.createElement(_components.p, null, "a. Se pueden utilizar etiquetas (LABEL) ", React.createElement(_components.a, {
    href: "http://litox9.files.wordpress.com/2008/09/image006.jpg"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 38px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 60.526315789473685%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAME/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAL/2gAMAwEAAhADEAAAAd8Nky4l/8QAHBAAAQQDAQAAAAAAAAAAAAAAAgEDERIAEyEy/9oACAEBAAEFAiKHWjtjfmqW1pA8T//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABYRAAMAAAAAAAAAAAAAAAAAABARIf/aAAgBAgEBPwGIf//EABsQAAIBBQAAAAAAAAAAAAAAABARAAIhUWHw/9oACAEBAAY/AuxKHZ7DP//EAB0QAAICAgMBAAAAAAAAAAAAAAEhABExYUFRcbH/2gAIAQEAAT8hRd3zAajSbfU+phNd7jUQFoYQUFc//9oADAMBAAIAAwAAABBU3//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABYRAQEBAAAAAAAAAAAAAAAAAAEQIf/aAAgBAgEBPxAcEn//xAAcEAEBAQEAAgMAAAAAAAAAAAABESEAUWExQeH/2gAIAQEAAT8QcWszrDKgWcikLUohEr1pWr9l8vnmezUCEHF+L1Vp1LBZpmfvCJaVa+313//Z'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"image006\"\n        title=\"image006\"\n        src=\"/static/d7cf6e5e7396bd15fe46294e1b364906/5ce5b/image006.jpg\"\n        srcset=\"/static/d7cf6e5e7396bd15fe46294e1b364906/5ce5b/image006.jpg 38w\"\n        sizes=\"(max-width: 38px) 100vw, 38px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), " que indican que dos lineas con el mismo nombre de etiqueta están unidas."), "\n", React.createElement(_components.p, null, "b. No se pueden unir dos componentes directamente, hace falta siempre una linea de conexión que debe salir perpendicular al componente"), "\n", React.createElement(_components.ol, {
    start: "6"
  }, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Seleccionar un fragmento de la hoja de trabajo. Botón ", React.createElement(_components.strong, null, "derecho"), " sobre un punto hoja trabajo, arrastrar ratón hasta completar un recuadro. Su contenido se puede ", React.createElement(_components.strong, null, "mover"), " ", React.createElement(_components.a, {
    href: "http://litox9.files.wordpress.com/2008/09/image007.jpg"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 25px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 96%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAATABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAUDBP/EABYBAQEBAAAAAAAAAAAAAAAAAAEAAv/aAAwDAQACEAMQAAABq8+k7DaDBAN//8QAGhAAAgIDAAAAAAAAAAAAAAAAAgMBEhATIP/aAAgBAQABBQJhVjcWHlVYsi3H/8QAFREBAQAAAAAAAAAAAAAAAAAAISD/2gAIAQMBAT8BGP/EABURAQEAAAAAAAAAAAAAAAAAABEg/9oACAECAQE/AWP/xAAeEAAABgIDAAAAAAAAAAAAAAAAAQIRIVEDMRAgQf/aAAgBAQAGPwKLYaTu+HYjn0QnHJ11/8QAHRABAAIBBQEAAAAAAAAAAAAAAREhABAgMUFx0f/aAAgBAQABPyFTQKizGckxgGH80ugoFZMEJCPi+tv/2gAMAwEAAgADAAAAEEQHwP/EABkRAQACAwAAAAAAAAAAAAAAAAEAEBEhMf/aAAgBAwEBPxA0S8mKZ//EABYRAQEBAAAAAAAAAAAAAAAAAAEQMf/aAAgBAgEBPxBIhDJ//8QAHRABAAEEAwEAAAAAAAAAAAAAAQARITFRQWFxgf/aAAgBAQABPxAPugYX2g6iNSLpC6FTlmNmFVOcWxc0lmBEoUIHpv2OWGPs5mvJ/9k='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"image007\"\n        title=\"image007\"\n        src=\"/static/e07f49ed23656743e140da1d59a52c27/c2a17/image007.jpg\"\n        srcset=\"/static/e07f49ed23656743e140da1d59a52c27/c2a17/image007.jpg 25w\"\n        sizes=\"(max-width: 25px) 100vw, 25px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), ", ", React.createElement(_components.strong, null, "pegar"), React.createElement(_components.a, {
    href: "http://litox9.files.wordpress.com/2008/09/image008.jpg"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 21px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 119.04761904761905%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAYABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMEBf/EABcBAQEBAQAAAAAAAAAAAAAAAAEAAgP/2gAMAwEAAhADEAAAAd8KY+T2hshMLWG//8QAGhAAAgIDAAAAAAAAAAAAAAAAAQMAAhESFP/aAAgBAQABBQJzSs9Nsx+m1TQGXVVh515n/8QAFxEBAAMAAAAAAAAAAAAAAAAAAQIRIP/aAAgBAwEBPwEjYuP/xAAWEQEBAQAAAAAAAAAAAAAAAAABICH/2gAIAQIBAT8BXY//xAAhEAAABQIHAAAAAAAAAAAAAAAAAQIRIRASIjJCUWGRkv/aAAgBAQAGPwIiJLuMhd0SS0XQMSHmOKFc8bGNXqn/xAAbEAEBAQADAQEAAAAAAAAAAAABESEAEDFR0f/aAAgBAQABPyETUl1nCSzoY/zp7ipGyctPH9fXTRpgw4EHUbq6/9oADAMBAAIAAwAAABD830H/xAAXEQADAQAAAAAAAAAAAAAAAAABEBEx/9oACAEDAQE/ECVcigX/xAAYEQADAQEAAAAAAAAAAAAAAAABEBEhMf/aAAgBAgEBPxCIirur/8QAHRABAQEAAgIDAAAAAAAAAAAAAREAMUFRcSFhkf/aAAgBAQABPxBesqvEQ6HzmgRMUJUL+skUwlDcIglPuqYpQirIRPcZnlxhQKJIx69b5AwASUad+Tc7/9k='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"image008\"\n        title=\"image008\"\n        src=\"/static/7980941fe651b2a62a2e2e3ab8eb0486/3aed4/image008.jpg\"\n        srcset=\"/static/7980941fe651b2a62a2e2e3ab8eb0486/3aed4/image008.jpg 21w\"\n        sizes=\"(max-width: 21px) 100vw, 21px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), " , ", React.createElement(_components.strong, null, "borrar"), " (tecla Supr) etc"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Variar tamaño y posición en la hoja de trabajo. Cuando se desee acceder a una posición de la hoja fuera de la pantalla, utilizar teclas de zoom ", React.createElement(_components.a, {
    href: "http://litox9.files.wordpress.com/2008/09/image009.jpg"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 60px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 33.33333333333333%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAHABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAEDBf/EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAAB0oMrQv8A/8QAGRABAAIDAAAAAAAAAAAAAAAAAgESAAMR/9oACAEBAAEFAiVGVUjX2n//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAZEAACAwEAAAAAAAAAAAAAAAAAAQIhMSL/2gAIAQEABj8CkmOOWdaf/8QAGRABAAMBAQAAAAAAAAAAAAAAAQARMSFB/9oACAEBAAE/ISkacZ3K2G/ICDDm3P/aAAwDAQACAAMAAAAQBA//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPxCn/8QAGhABAQEBAQEBAAAAAAAAAAAAAREhAFGh4f/aAAgBAQABPxCoYtcSq0kzIfvNU7pEYPDNuT5wrJVXTcb5O//Z'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"image009\"\n        title=\"image009\"\n        src=\"/static/dd61729d37a90461990392b46f258704/59e87/image009.jpg\"\n        srcset=\"/static/dd61729d37a90461990392b46f258704/59e87/image009.jpg 60w\"\n        sizes=\"(max-width: 60px) 100vw, 60px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), " y de fijar el centro de la pantalla ", React.createElement(_components.a, {
    href: "http://litox9.files.wordpress.com/2008/09/image010.jpg"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 21px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 95.23809523809523%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAATABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAEDBQT/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAv/aAAwDAQACEAMQAAAB1uOzNN4SQEg//8QAGhAAAgMBAQAAAAAAAAAAAAAAAgMAAQQSIP/aAAgBAQABBQIzoKzP7XH5xbMuYWB4/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFhEAAwAAAAAAAAAAAAAAAAAAESAh/9oACAECAQE/AYE//8QAHhABAAEDBQEAAAAAAAAAAAAAAREAAiEQEyBRYZH/2gAIAQEABj8Cyh1RuJKwe6SzJRdeJn7x/8QAHRABAAEDBQAAAAAAAAAAAAAAAUEAITEQIFFhgf/aAAgBAQABPyG5MZS0zA5hoTLYieqQJHLGvNn/2gAMAwEAAgADAAAAEBgXQf/EABYRAAMAAAAAAAAAAAAAAAAAABEgIf/aAAgBAwEBPxClP//EABYRAQEBAAAAAAAAAAAAAAAAAAEgMf/aAAgBAgEBPxAN4//EAB4QAAICAwADAQAAAAAAAAAAAAERITEAEEFRcYGh/9oACAEBAAE/EAgsEAaQGsPjZCB7rirSlBwnVEt4lVhyJ7QA/QAYjAUE3zQochx71//Z'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"image010\"\n        title=\"image010\"\n        src=\"/static/6ab8cf0f5201e4aa903aaed1d67948bf/3aed4/image010.jpg\"\n        srcset=\"/static/6ab8cf0f5201e4aa903aaed1d67948bf/3aed4/image010.jpg 21w\"\n        sizes=\"(max-width: 21px) 100vw, 21px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }))), "\n"), "\n"), "\n", React.createElement(_components.p, null, "8)  Opcionalmente: dibujar recuadros ", React.createElement(_components.a, {
    href: "http://litox9.files.wordpress.com/2008/09/image013.jpg"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 20px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 100%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAMEBQH/xAAXAQEBAQEAAAAAAAAAAAAAAAAABAID/9oADAMBAAIQAxAAAAHQg7T1NuDnTHGFgH//xAAbEAACAgMBAAAAAAAAAAAAAAABAgADBBAzE//aAAgBAQABBQK201k5LATI6Wc4yK88atf/xAAYEQACAwAAAAAAAAAAAAAAAAABAgMRIP/aAAgBAwEBPwF5GD0Mf//EABYRAQEBAAAAAAAAAAAAAAAAAAECIP/aAAgBAgEBPwEkTH//xAAgEAABAwIHAAAAAAAAAAAAAAABAAIQEXESEzNhkZLB/9oACAEBAAY/AgA2td1XLHaGWPidaBiaDcLTZxH/xAAdEAACAQQDAAAAAAAAAAAAAAABERAAITFhofDx/9oACAEBAAE/IRGDEm6JLW6JiSL9KauQjBLxcVeFj//aAAwDAQACAAMAAAAQhB9B/8QAFhEAAwAAAAAAAAAAAAAAAAAAASAx/9oACAEDAQE/EBqSf//EABcRAAMBAAAAAAAAAAAAAAAAAAERIDH/2gAIAQIBAT8QcDsf/8QAHhAAAgAHAQEAAAAAAAAAAAAAAREAECExUXHwQcH/2gAIAQEAAT8QIOhFECDwm9LQo4SSNoVMvSzDjYMtIUKl09CO++S//9k='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"image013\"\n        title=\"image013\"\n        src=\"/static/e849dfd4170bcbacea099f945bc1d59b/55eb8/image013.jpg\"\n        srcset=\"/static/e849dfd4170bcbacea099f945bc1d59b/55eb8/image013.jpg 20w\"\n        sizes=\"(max-width: 20px) 100vw, 20px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), ", texto ", React.createElement(_components.a, {
    href: "http://litox9.files.wordpress.com/2008/09/image014.jpg"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 18px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 105.55555555555556%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAVABQDASIAAhEBAxEB/8QAGQABAAIDAAAAAAAAAAAAAAAAAAMEAgUG/8QAFwEBAQEBAAAAAAAAAAAAAAAAAAMBAv/aAAwDAQACEAMQAAABnq5a/Z9WOKV4wuA//8QAHRAAAgIBBQAAAAAAAAAAAAAAAgMEEBQAERITIf/aAAgBAQABBQKQ81sym1L27eXujSBljLr/xAAWEQEBAQAAAAAAAAAAAAAAAAABEBH/2gAIAQMBAT8BV2ZP/8QAFhEBAQEAAAAAAAAAAAAAAAAAEAER/9oACAECAQE/AYaf/8QAHxAAAQMDBQAAAAAAAAAAAAAAAQACERASMUJhcZLR/9oACAEBAAY/AoGOJXrKGXW4UB5cINLjM7Fau1P/xAAdEAEAAgICAwAAAAAAAAAAAAABESEAEGFxMUHB/9oACAEBAAE/IVUdCrpZ54ypqIF8H3UDJAsHeM0mTT1r0BosMUIZDy1//9oADAMBAAIAAwAAABBQF4H/xAAYEQADAQEAAAAAAAAAAAAAAAAAAREQUf/aAAgBAwEBPxCskIjmf//EABYRAQEBAAAAAAAAAAAAAAAAAAEQEf/aAAgBAgEBPxAGE1P/xAAcEAEBAAMBAAMAAAAAAAAAAAABEQAhcTEQUaH/2gAIAQEAAT8QpSERZwMXcuIwaL7zlu/veOs9lzZrisYRMKKYeHM0sIJqhZ51xfKIjt/fj//Z'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"image014\"\n        title=\"image014\"\n        src=\"/static/6002f97725414e2513805c3e86e8ed96/156d6/image014.jpg\"\n        srcset=\"/static/6002f97725414e2513805c3e86e8ed96/156d6/image014.jpg 18w\"\n        sizes=\"(max-width: 18px) 100vw, 18px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), ", etc"), "\n", React.createElement(_components.ol, {
    start: "9"
  }, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Guardar fichero NOMBRE.DSN"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Simular: Teclas inferiores ", React.createElement(_components.a, {
    href: "http://litox9.files.wordpress.com/2008/09/image015.jpg"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 163px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 13.924050632911392%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAADABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEE/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAdYiCP/EABYQAQEBAAAAAAAAAAAAAAAAAAEhMf/aAAgBAQABBQJxaN//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAYEAACAwAAAAAAAAAAAAAAAAAAAQIQcf/aAAgBAQAGPwJ1LT//xAAbEAACAgMBAAAAAAAAAAAAAAAAARFBMVFxwf/aAAgBAQABPyF5VsZDF0M7eD//2gAMAwEAAgADAAAAEHQ//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGxABAQACAwEAAAAAAAAAAAAAAREAQSExUWH/2gAIAQEAAT8QVGQNhHlN6xCPR0eYRKWjx8deZ//Z'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"image015\"\n        title=\"image015\"\n        src=\"/static/f5edc5750aaf5ff20c05b2ab39d1a648/d2f63/image015.jpg\"\n        srcset=\"/static/f5edc5750aaf5ff20c05b2ab39d1a648/ff44c/image015.jpg 158w,\n/static/f5edc5750aaf5ff20c05b2ab39d1a648/d2f63/image015.jpg 163w\"\n        sizes=\"(max-width: 163px) 100vw, 163px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), ". Modificar las entradas"), "\n"), "\n"), "\n", React.createElement(_components.p, null, "Un saludo."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
